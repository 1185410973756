import { LitElement } from 'lit-element';

/**
 * getPresets
 * get element presets based on rule sets and weights
 *
 * @param {LitElement} component lit element web component
 */
export default (component: LitElement): void => {
  const tag = component.nodeName.toLowerCase();
  const presets = window?.HE_UCL?.presets?.[tag] || [];
  const presetsWeighted = [];
  let presetsGlobal;

  // get preset based on paths, globs & placements
  presets.forEach((preset) => {
    const isGlob = preset?.path?.includes('*');
    const path = preset?.path ? preset.path.replace('*', '') : '';
    let weight = path.length;

    // global presets `@`
    if (preset.path === '@') {
      presetsGlobal = preset;
    }

    // return, no glob or path match
    if (
      (isGlob && !window.location.pathname.startsWith(path)) ||
      (!isGlob && window.location.pathname !== path)
    ) {
      return;
    }

    // placement match
    if (preset.placement) {
      if (preset.placement === component.dataset.placement) {
        weight += 1000;
      } else {
        return;
      }
    }

    // add to weighted presets
    presetsWeighted.push({ ...preset, weight });
  });

  // return, no presets matched
  if (!presetsGlobal && !presetsWeighted.length) {
    return;
  }

  // sort to find most weighted preset
  const preset = presetsWeighted.sort((a, b) => b.weight - a.weight)[0];

  // set attributes, combine global and most weighted presets
  Object.entries({
    ...presetsGlobal?.attributes,
    ...preset?.attributes,
  }).forEach(([property, value]) => {
    if (!component.hasAttribute(property) && value !== '') {
      component.setAttribute(property, value as string);
    }
  });
};
