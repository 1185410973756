import { css, unsafeCSS, LitElement, TemplateResult } from 'lit-element';
import { customElement, property } from 'lit-element/lib/decorators';
import { CSSResultGroup } from '../../ts/types';
import getPresets from '../../utils/getPresets';
import styles from './styles.scss';
import template from './template';

@customElement('sonic-tooltip')
export default class WebComponent extends LitElement {
  @property({ type: String })
  label;

  @property({ type: String })
  position;

  @property({ type: Boolean })
  slotClicked = false;

  static get styles(): CSSResultGroup {
    return [css`${unsafeCSS(styles)}`];
  }

  render(): TemplateResult {
    // no label, no tooltip
    if (!this.label) {
      return null;
    }
    this.tabIndex = 0;
    // return template
    return template({
      label: this.label,
    });
  }

  protected firstUpdated(
    _changedProperties: Map<string | number | symbol, unknown>
  ): void {
    this.addEventListener('focusin', () => {
      const element = this.shadowRoot.querySelector('#trigger');
      element.classList.add('is-active');
    });
    this.addEventListener('focusout', (event) => {
      if (!this.contains(event.relatedTarget as Node)) {
        const element = this.shadowRoot.querySelector('#trigger');
        element.classList.remove('is-active');
      }
    });
  }

  /*
   * connectedCallback
   *
   * fires each time a custom element is appended
   * into a document-connected element.
   */
  connectedCallback(): void {
    super.connectedCallback();

    // presets
    getPresets(this);
  }
}
