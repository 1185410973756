import { html, TemplateResult } from 'lit-element';

interface TemplateProps {
  label;
}

export default ({ label }: TemplateProps): TemplateResult => {
  return html`
      <button id="trigger" class="label underline text-sm cursor-pointer bg-transparent border-0"><slot name="label">${label}</slot></button>
      <slot class="content shadow-lg bg-white p-4 rounded-md text-left text-sm normal-case z-[99999]"></slot>
  `;
};
